// Primary and contextual colors
export const purple     = '#7f5edf'
export const lilac      = '#cab1f2'
export const pink       = '#f0b4d4'
export const red        = '#f63333'
export const coral      = '#fe8968'
export const yellow     = '#ffcf00'
export const green      = '#19a359'
export const blue       = '#7fc8e9'

// Currently not really used
export const teal       = '#248485'

// Backgrounds
export const beige      = '#fcfbf2'
export const coolGray   = '#f5f4fa'
export const navy       = '#242142'

// Bootstrap mappings
export const primary    = purple
export const secondary  = pink
export const success    = green
export const info       = blue
export const warning    = yellow
export const danger     = red
export const bright     = beige
export const light      = coolGray
export const dark       = navy

const COLORS = {
  purple, lilac, pink, red, coral, yellow, green, blue, teal,
  beige, coolGray, navy,
  primary, secondary, success, info, warning, danger, bright, light, dark,
}

export default COLORS
